import axios from 'axios';
import { BASE_CURRENCY, DEFAULT_COIN_DATA } from 'config/constants';
import {
	calculateBalancePrice,
	calculateOraclePrice,
	calculatePricePercentage,
	formatToCurrency,
	donutFormatPercentage,
} from 'utils/currency';
import { bitlyReadContract, getPrice, getStableCoins } from 'utils/wagmiWrapper';
import { requestConstant } from './appActions';

export const SET_PRICES_AND_ASSET_PENDING = 'SET_PRICES_AND_ASSET_PENDING';
export const SET_PRICES_AND_ASSET_SUCCESS = 'SET_PRICES_AND_ASSET_SUCCESS';
export const SET_PRICES_AND_ASSET_FAILURE = 'SET_PRICES_AND_ASSET_FAILURE';
export const SET_ALL_COINS = 'SET_ALL_COINS';
export const SET_ALL_PAIRS = 'SET_ALL_PAIRS';
export const SET_EXCHANGE = 'SET_EXCHANGE';
export const SET_DASH_TOKEN = 'SET_DASH_TOKEN';

export const setPricesAndAssetPending = () => {
	return (dispatch) => {
		dispatch({ type: SET_PRICES_AND_ASSET_PENDING });
	};
};

export const setPricesAndAsset = (balance, coins) => {
	return (dispatch) => {
		dispatch({ type: SET_PRICES_AND_ASSET_PENDING });
		getPrices({ coins })
			.then((prices) => {
				const totalAsset = calculateBalancePrice(balance, prices, coins);
				dispatch({
					type: SET_PRICES_AND_ASSET_SUCCESS,
					payload: {
						oraclePrices: prices,
						totalAsset,
						chartData: generateChartData(balance, prices, coins, totalAsset),
					},
				});
			})
			.catch((err) => {
				dispatch({ type: SET_PRICES_AND_ASSET_FAILURE });
			});
	};
};

export const setCoins = (allCoins) => {
	return (dispatch) => {
		dispatch({
			type: SET_ALL_COINS,
			payload: {
				allCoins,
			},
		});
	};
};

export const setAllPairs = (allPairs) => {
	return (dispatch) => {
		dispatch({
			type: SET_ALL_PAIRS,
			payload: {
				allPairs,
			},
		});
	};
};

export const setExchange = (exchange) => {
	return (dispatch) => {
		dispatch({
			type: SET_EXCHANGE,
			payload: {
				exchange,
			},
		});
	};
};

export const setDashToken = (dashToken) => {
	return (dispatch) => {
		dispatch({
			type: SET_DASH_TOKEN,
			payload: {
				dashToken,
			},
		});
	};
};

const ENDPOINTS = {
	GET_PRICE: '/oracle/prices',
};

export const getPairBasedOnStableCoin = async (coin) => {
    const stableCoins = await getStableCoins();
    if (stableCoins.length == 0) {
        return null;
    }

    for (const stableCoin of stableCoins) {
        if (coin == stableCoin) {
            return undefined;
        }
        const info = await bitlyReadContract("tokenInfo", [coin]);
        for (const pairInfo of info[6]) {
            if (pairInfo.tokenY == stableCoin) {
                return pairInfo.pair;
            }
        }
    }

    return null;
};

export const getPrices = async ({
	coins = {},
}) => {
    const prices = {};
    const stableCoins = await getStableCoins();
    if (stableCoins.length == 0) {
        return prices;
    }

	const assets = Object.keys(coins);
    for (const coin of assets) {
        for (const stableCoin of stableCoins) {
            const pairAddress = await getPairBasedOnStableCoin(coin);
            if (pairAddress) {
                const price = await getPrice(pairAddress);
                prices[coin] = price;
                break;
            }
        }
    }
	// const { data: prices = {} } = await axios.get(ENDPOINTS.GET_PRICE, {
	// 	params: { amount, quote, assets },
	// });
	return prices;
};

export const generateChartData = (balance, prices, coins, totalAsset) => {
	const data = [];

	Object.keys(coins).forEach((currency) => {
		const { min } = coins[currency] || DEFAULT_COIN_DATA;
		const currencyBalance = calculateOraclePrice(
			balance[`${currency}_in_bank`],
			prices[currency]
		);
		const balancePercent = calculatePricePercentage(
			currencyBalance,
			totalAsset
		);
		data.push({
			...coins[currency],
			balance: balancePercent,
			balanceFormat: formatToCurrency(currencyBalance, min),
			balancePercentage: donutFormatPercentage(balancePercent),
		});
	});

	return data;
};

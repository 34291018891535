import axios from 'axios';
import ICONS from 'config/icons';
import { requestAuthenticated } from 'utils';
import STRINGS from 'config/localizedStrings';
import { playBackgroundAudioNotification } from 'utils/utils';
import { ERC20ReadContract, ERC20WriteContract, getSubscribeMode, pairReadContract, pairWriteContract } from 'utils/wagmiWrapper';
import { price2Point, toPlainBalance } from 'utils/bitlyBignumber';
import { message } from 'antd';
import { API_URL } from 'config/constants';

// Set orders from websocket
export function setUserOrders(orders) {
	return {
		type: 'SET_USER_ORDERS',
		payload: orders,
	};
}

export function addOrder(order) {
	return {
		type: 'ADD_ORDER',
		payload: { order },
	};
}

export function updateOrder(order) {
	return {
		type: 'UPDATE_ORDER',
		payload: { order },
	};
}

export function removeOrder(ids = {}) {
	return {
		type: 'REMOVE_ORDER',
		payload: { ids: [ids.id] },
	};
}

export const submitOrder = async (order) => {
    const pairInfo  = await pairReadContract('pairInfo', undefined, order.symbol);
    const originToken = order.side == 'buy' ? pairInfo[1] : pairInfo[0];
    const targetToken = order.side == 'buy' ? pairInfo[0] : pairInfo[1];
    const decimal  = await ERC20ReadContract('decimals', undefined, originToken);
    order.estimatedPrice = order.type == 'limit' ? order.size * order.price : order.estimatedPrice;
    const orderSize = order.side == 'sell' ? order.size: order.estimatedPrice;

    let ret;
    if (order.type == 'limit') {
        ret = await pairWriteContract('limitOrder', [
            originToken,
            price2Point(order.price).toNumber(),
            toPlainBalance(orderSize, decimal).toString(),
            order.symbol,
            order.symbol
        ], order.symbol);
    } else {
        const curPoint = await pairReadContract("curPoint", undefined, order.symbol);
        ret = await pairWriteContract('marketOrder', [
            originToken,
            toPlainBalance(orderSize, decimal).toString(),
            curPoint,
            order.slippage,
            order.symbol,
            order.symbol
        ], order.symbol);
    }
    fetch(API_URL+`/api/update?pair=${order.symbol}`);

    return ret;
};

export const cancelOrder = (orderId, settings) => (dispatch) => {
    const [pair, originToken, targetToken, point] = orderId.split("-");
	pairWriteContract("cancelLimitOrder", [originToken, point], pair)
		.then((res) => {
            if (!res.ok) {
                throw res.res;
            }
			dispatch({
				type: 'REMOVE_ORDER',
				payload: {ids: [orderId]},
			});
			playBackgroundAudioNotification('cancel_order', settings);
			dispatch({
				type: 'SET_SNACK_NOTIFICATION',
				payload: {
					icon: ICONS['CLOSE_CROSS'],
					content: STRINGS['CANCEL_SUCCESS_TEXT'],
				},
			});
		})
		.catch((err) => {message.error(err)});
};

export const claim = (orderId, settings) => (dispatch) => {
    const [pair, originToken, targetToken, point] = orderId.split("-");
	pairWriteContract("claimEarning", [originToken, point], pair)
		// .then((res) => {
        //     if (!res.ok) {
        //         throw res.res;
        //     }
		// 	dispatch({
		// 		type: 'REMOVE_ORDER',
		// 		payload: {ids: [orderId]},
		// 	});
		// 	playBackgroundAudioNotification('cancel_order', settings);
		// 	dispatch({
		// 		type: 'SET_SNACK_NOTIFICATION',
		// 		payload: {
		// 			icon: ICONS['CLOSE_CROSS'],
		// 			content: STRINGS['CANCEL_SUCCESS_TEXT'],
		// 		},
		// 	});
		// })
		.catch((err) => {message.error(err)});
};

export const cancelAllOrders = (symbol = '', settings) => (dispatch) => {
    pairWriteContract("cancelAllLimitOrders", undefined, symbol)
		.then((res) => {
            if (!res.ok) {
                throw res.res;
            }
			dispatch({
				type: 'CANCEL_ALL_ORDERS',
			});
			playBackgroundAudioNotification('cancel_order', settings);
			dispatch({
				type: 'SET_SNACK_NOTIFICATION',
				payload: {
					icon: ICONS['CLOSE_CROSS'],
					content: STRINGS['CANCEL_SUCCESS_TEXT'],
				},
			});
		})
		.catch((err) => {});
};

export const claimAll = (symbol = '', settings) => (dispatch) => {
    pairWriteContract("claimAllEarnings", undefined, symbol)
		// .then((res) => {
        //     if (!res.ok) {
        //         throw res.res;
        //     }
		// 	dispatch({
		// 		type: 'CANCEL_ALL_ORDERS',
		// 	});
		// 	playBackgroundAudioNotification('cancel_order', settings);
		// 	dispatch({
		// 		type: 'SET_SNACK_NOTIFICATION',
		// 		payload: {
		// 			icon: ICONS['CLOSE_CROSS'],
		// 			content: STRINGS['CANCEL_SUCCESS_TEXT'],
		// 		},
		// 	});
		// })
		.catch((err) => {});
};

export const executeBroker = (values) => {
	const options = {
		method: 'POST',
		body: JSON.stringify(values),
	};

	return requestAuthenticated('/broker/execute', options);
};

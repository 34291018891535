import React, { Fragment } from 'react';
import { Button } from 'antd';

import { STATIC_ICONS } from 'config/icons';
import { Link } from 'react-router-dom';

const Step6 = ({ handleNext, handleScreenChange }) => {
	return (
		<Fragment>
			<div className="title">Confirmation</div>
			<div className="preview-coin-container">
				<div className="preview-content">
					<img
						className="icon-preview"
						src={STATIC_ICONS.RED_WARNING}
						alt="new_coin"
					/>
				</div>
			</div>
			<p className="fiat-confirm-content">
				Before add your token, please confirm you are now connecting to the right blockchain.
			</p>
            <p className="fiat-confirm-content">
				<span style={{color: "red"}}>Already listed token? List pair for your token </span> 
                <Link to="/list-pair"> <b style={{color: "blue"}}>HERE</b> </Link>
			</p>
			<div className="btn-wrapper">
				{/* <Button
					type="primary"
					className="green-btn"
					onClick={() => handleScreenChange('step3')}
				>
					Back
				</Button> */}
				<div className="separator"></div>
				<Button type="primary" className="green-btn" onClick={handleNext}>
					I understand, proceed
				</Button>
			</div>
		</Fragment>
	);
};

export default Step6;

import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { wagmiUseAccountHOC } from 'utils/wagmiWrapper';
import DesktopStake from './DesktopStake';
import MobileStake from './MobileStake';

const Index = (props) => {
	return (
        <Fragment>
            { 
                isMobile ? 
                <MobileStake /> : 
                <DesktopStake account={props.account} />
            }
        </Fragment>
    );
};

export default wagmiUseAccountHOC(Index);

import React, { Fragment, useState } from 'react';
import {
	Input,
	Button,
	Form,
    Select,
    message
} from 'antd';
import { bitlyWriteContract, ERC20ReadContracts, getStableCoins } from 'utils/wagmiWrapper';
import { requestConstant } from 'actions/appActions';

const { Search, TextArea } = Input;

const AssetConfig = (props) => {
	const [submitting, setSubmitting] = useState(false);
    const [pairFormData, setPairFormData] = useState({});
    const [options, setOptions] = useState([]);
	const [form] = Form.useForm();

	const {
        handleConfirmation,
	} = props;

    React.useEffect(()=>{
        getStableCoins().then((baseTokenAddresses)=>{
            const args = [];
            for (let index = 0; index < baseTokenAddresses.length; index++) {
                const addr = baseTokenAddresses[index];
                args.push({
                    functionName: "symbol",
                    address: addr
                });
            }
            ERC20ReadContracts(args).then(symbols=>{
                const ops = [];
                for (let index = 0; index < baseTokenAddresses.length; index++) {
                    const addr = baseTokenAddresses[index];
                    const s = symbols[index];
                    ops.push({value: addr, label: `${s}(${addr})`});
                }
                setOptions(ops);
            });
        });
    }, []);

	const handleSubmit = async (values) => {
        setSubmitting(true);
        if (!pairFormData.base || !pairFormData.traded) {
            handleConfirmation(false, "Please fill the form first");
            return;
        }
        const { ok, res } = await  bitlyWriteContract(
            "listPair",
            [
                pairFormData.traded,
                pairFormData.base,
                10
            ]
        );
        if (ok) {
            handleConfirmation(true, "Pair listed successfully");
        } else {
            handleConfirmation(false, res);
        }
        setSubmitting(false);
	};

    const handleChange = (e) => {
		const newPairFormData = {
			...pairFormData,
			[e.target.name]: e.target.value,
		};
		setPairFormData(newPairFormData);
	};

    const onSelectChange = (value) => {
        const newPairFormData = {
			...pairFormData,
			base: value,
		};
		setPairFormData(newPairFormData);
    };

    // const genSelectOptions = () => {
    //     const ops = [];
    //     for (let index = 0; index < baseTokenAddresses.length; index++) {
    //         const addr = baseTokenAddresses[index];
    //         const s = symbols[index];
    //         ops.push({value: addr, label: `${s}(${addr})`});
    //     }
    //     return ops;
    // }

	return (
		<Fragment>
			<div className="title">List new trade pair</div>
			<Form
				form={form}
				initialValues={pairFormData}
				name="AssetConfigForm"
				onFinish={handleSubmit}
			>
                <div className="section-wrapper">
					<div className="sub-title">Contract Address of Token to be Traded</div>
					<div className="d-flex align-items-center">
						{/* <div className="preview-icon"></div> */}
						<div className="md-field-wrap">
                            <Input
                                name="traded"
                                onChange={handleChange}
                                value={pairFormData.address}
                            />
						</div>
					</div>
				</div>
				<div className="section-wrapper">
					<div className="sub-title">Contract Address of Base Token</div>
					<div className="d-flex align-items-center">
						{/* <div className="preview-icon"></div> */}
						<div className="md-field-wrap">
                            {/* <Input
                                name="base"
                                onChange={handleChange}
                                value={pairFormData.address}
                            /> */}
                            <Select
                                placeholder="Select base token"
                                onChange={onSelectChange}
                                options={options}
                            />
						</div>
					</div>
				</div>
				<div className="btn-wrapper">
					<Button
						type="primary"
						className="green-btn"
						htmlType="submit"
						disabled={submitting}
					>
						Submit
					</Button>
				</div>
			</Form>
		</Fragment>
	);
};

export default AssetConfig;

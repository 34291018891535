import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { STATIC_ICONS } from 'config/icons';
import Coins from '../Coins';
import IconToolTip from '../IconToolTip';
import { getNetworkLabelByKey } from 'utils/wallet';
import { formatPercentage } from 'utils/currency';
import { Link } from 'react-router-dom';
import RemoveConfirmation from '../Confirmation';
import { withRouter } from 'utils/router';

const basicCoins = ['btc', 'xht', 'eth', 'usdt'];

export const getTabParams = () => {
	let paramsString = window.location.search.replace('?', '');
	let activeTab = {};
	if (paramsString.length) {
		let splitValue = paramsString.split('&');
		splitValue.forEach((value) => {
			let temp = value.split('=');
			activeTab[temp[0]] = temp[1];
		});
	}
	return activeTab;
};

const Final = (props) => {
	const handleMoveBack = () => {
        props.router.navigate("/");
	};

    const handleNext = () => {
        props.router.navigate("/list-pair");
    };

	return (
		<Fragment>
			<div className="title">congratulations!</div>
			<div className="preview-coin-container">
				<div className="preview-content">
					<img
						className="icon-preview"
						src={STATIC_ICONS.BLACK_CHECK}
						alt="check"
					/>
				</div>
			</div>
			<p className="fiat-confirm-content">
				Token has already been listed to bitly exchange. You need add a trading pair for your token.
			</p>
            <div className="btn-wrapper">
                <Button className="green-btn" type="primary" onClick={handleMoveBack}>
                    Head to Market
                </Button>
                <div className="separator"></div>
                <Button
                    type="primary"
                    onClick={()=>{}}
                    disabled={true}
                />
                <Button
                    className="green-btn"
                    type="primary"
                    onClick={handleNext}
                >
                    Go listing pair
                </Button>
            </div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		exchange: state.asset && state.asset.exchange ? state.asset.exchange : {},
		constants: state.app.constants,
		allCoins: state.asset.allCoins,
	};
};

export default connect(mapStateToProps, null)(withRouter(Final));

import React, { Component } from 'react';
import { PriceChange, EditWrapper, Coin } from 'components';
import SparkLine from './SparkLine';
import { formatToCurrency } from 'utils/currency';
import STRINGS from 'config/localizedStrings';
import { connect } from 'react-redux';
import withConfig from 'components/ConfigProvider/withConfig';
import { calcBlockHeight, getPrice } from 'utils/wagmiWrapper';
import { point2Price } from 'utils/bitlyBignumber';

class MarketRow extends Component {
    state = {
        price: 0,
        priceChangePct: 0,
        priceChange: 0,
        volume: 0
    };

    setPrice = () => {
        const { market } = this.props;
        getPrice(market.key).then(price=>{
            this.setState({ price });
        });
    }

    setVolume = async () => {
        
    };

    setPriceChange = async () => {
        const { market } = this.props;
        const curPrice = await getPrice(market.key);
        const lastPrice = await getPrice(market.key, await calcBlockHeight(-1*24*60*60));
        const pct = ((curPrice-lastPrice) / lastPrice * 100).toFixed(2);
        const change = (curPrice - lastPrice).toFixed(5);
        this.setState({ priceChangePct: pct.toString() + "%", priceChange: change });
    };

    componentDidMount() {
		this.setPrice();
        this.setPriceChange();
	}

	componentDidUpdate(prevProps) {
		const { market } = this.props;
        if (JSON.stringify(market) !== JSON.stringify(prevProps.market)) {
            this.setPrice();
            this.setPriceChange();
        }
	}

	render() {
		const {
			market,
			chartData,
			handleClick,
			loading,
			index,
			isAsset = false,
		} = this.props;

		const {
			key,
			increment_price,
			display_name,
			pair_base_display,
			pair_2_display,
			icon_url,
			symbol,
			pairBase,
			fullname,
			sourceType: type,
		} = market;

		const isBrokerage = type === 'network' || type === 'broker';

        // const getPriceChange = () => {
        //     readContract({
        //         overrides: {
        //             blockTag
        //         }
        //     })
        // };

		return (
			<tr
				id={`market-list-row-${key}`}
				className="table-row table-bottom-border"
				onClick={() => handleClick(key)}
			>
				<td className="sticky-col">
					{!loading ? (
						<div className="d-flex align-items-center">
							<Coin icon_url={icon_url} />
							<div className="px-2">{isAsset ? fullname : display_name}</div>
						</div>
					) : (
						<div
							className="loading-anime"
							style={{
								animationDelay: `.${index + 1}s`,
							}}
						/>
					)}
				</td>
				<td>
					{!loading ? (
						<div>
							<span className="title-font ml-1">
								{formatToCurrency(this.state.price, increment_price)}
							</span>
							<span className="title-font ml-2">{pair_2_display}</span>
						</div>
					) : (
						<div
							className="loading-anime"
							style={{
								animationDelay: `.${index + 1}s`,
							}}
						/>
					)}
				</td>
				{isAsset && (
					<td>
						{type === 'network' ? (
							<EditWrapper stringId="DIGITAL_ASSETS.NETWORK">
								{STRINGS['DIGITAL_ASSETS.NETWORK']}
							</EditWrapper>
						) : type === 'broker' ? (
							<EditWrapper stringId="DIGITAL_ASSETS.BROKER">
								{STRINGS['DIGITAL_ASSETS.BROKER']}
							</EditWrapper>
						) : (
							<EditWrapper stringId="DIGITAL_ASSETS.ORDERBOOK">
								{STRINGS['DIGITAL_ASSETS.ORDERBOOK']}
							</EditWrapper>
						)}
					</td>
				)}
				<td>
					{isBrokerage ? (
						<EditWrapper stringId="DIGITAL_ASSETS.BROKERAGE">
							{STRINGS['DIGITAL_ASSETS.BROKERAGE']}
						</EditWrapper>
					) : (
						<PriceChange priceDifference={this.state.priceChange} priceDifferencePercent={this.state.priceChangePct} />
					)}
				</td>
				{/* {!isAsset && (
					<td>
						{!loading ? (
							<div>
                                <span className="title-font ml-1">{this.state.volume}</span>
                                <span className="title-font ml-2">{pair_base_display}</span>
							</div>
						) : (
							<div
								className="loading-anime"
								style={{
									animationDelay: `.${index + 1}s`,
								}}
							/>
						)}
					</td>
				)} */}
				{isAsset && (
					<td>
						<div className="ml-1">{symbol.toUpperCase()}</div>
					</td>
				)}
				{isAsset && (
					<td>
						<div className="d-flex">
							<div className="icon-container">
								<div
									className={
										pairBase.type === 'blockchain'
											? 'squar-box'
											: pairBase.type === 'fiat'
											? 'circle-icon'
											: 'triangle-icon'
									}
								/>
							</div>
							<div className="ml-1 caps-first">{pairBase.type}</div>
						</div>
					</td>
				)}
				<td className="td-chart">
					{isBrokerage ? (
						<EditWrapper stringId="DIGITAL_ASSETS.BROKERAGE">
							{STRINGS['DIGITAL_ASSETS.BROKERAGE']}
						</EditWrapper>
					) : (
						<SparkLine
							data={
								!chartData[key] ||
								(chartData[key] &&
									chartData[key].close &&
									chartData[key].close.length < 2)
									? { close: [0.1, 0.1, 0.1], open: [] }
									: chartData[key]
							}
							containerProps={{ style: { height: '100%', width: '100%' } }}
						/>
					)}
				</td>
			</tr>
		);
	}
}

const mapStateToProps = (store) => ({
    ERC20ABI: store.app.ERC20ABI,
    bitlyABI: store.app.bitlyABI,
    pairABI: store.app.pairABI,
    contractsAddress: store.app.contractsAddress
});

export default connect(mapStateToProps)(withConfig(MarketRow));

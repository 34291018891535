import React from 'react';
import { useAccount } from 'wagmi';
import {
	readContract,
	readContracts,
	writeContract,
	simulateContract,
	fetchBlockNumber,
	getAccount,
    watchContractEvent,
} from '@wagmi/core';
import {
	requestERC20ABI,
	requestBitlyABI,
	requestConstant,
	requestPairABI,
	requestBankABI,
} from 'actions/appActions';
import { point2Price } from './bitlyBignumber';
import { supportedChains, wagmiConfig } from 'config/constants';
import { createPublicClient, http, parseAbiItem, parseGwei } from 'viem';
import { Component } from 'react';

export const getSubscribeMode = async () => {
    const { data: constants = {} } = await requestConstant();
    const chain = await getCurrentChain();
    return constants.network[chain.name].subscribe_mode;
};

export const getCurrentChain = async () => {
	const { chain } = await getAccount(wagmiConfig);
	const { data: constants = {} } = await requestConstant();
	if (!chain) {
		return supportedChains?.[0];
	}
	return chain;
};

export const getStableCoins = async () => {
    const chain = await getCurrentChain();
    const { data: constants = {} } = await requestConstant();
    return constants.network[chain.name].stable_coins || [];
};

export const getAccountAddress = async () => {
	return (await getAccount(wagmiConfig)).address;
};

export const wagmiUseAccountHOC = (Component) => {
	return (props) => {
		const account = useAccount();
		console.log('[DEBUG]: wagmiUseAccountHOC: account: ', account);
		return <Component account={account} {...props} />;
	};
};

export const watchPairEvent = async (pairAddress, eventName, onLogs, args=undefined) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestPairABI();
    console.log('[DEBUG]: watchPairEvent:',
        'chain: ', chain,
        'address', pairAddress,
        'event', eventName
    );
	return watchContractEvent(wagmiConfig, {
		address: pairAddress,
		abi,
		eventName,
		onLogs,
        args,
        chainId: chain.id
	});
};

/**
 *
 * @param {number} relativeTime : metric by seconds
 * @returns {number} block height
 */
export const calcBlockHeight = async (relativeTime) => {
	if (relativeTime > 0) {
		relativeTime = 0;
	}
	const chain = await getCurrentChain();
	const blockNumber = await fetchBlockNumber(wagmiConfig, {
        chainId: chain.id
    });
	const { data: constants = {} } = await requestConstant();
	console.log(
		'[DEBUG]: calcBlockHeight: connected to: ',
		chain,
		', blockNumber: ',
		blockNumber,
		', relativeTime: ',
		relativeTime
	);
	const rate = constants.network[chain.name].block_rate;
	const targetBlockNumber = Number(blockNumber) + parseInt(relativeTime / rate);
	return targetBlockNumber >= 1 ? targetBlockNumber : 1;
};

/**
 *
 * @param {number} blockHeight block_height
 * @returns {Date} local datetime
 */
export const calcRealTime = async (blockHeight) => {
	const chain = await getCurrentChain();
	const blockNumber = await fetchBlockNumber(wagmiConfig, {
        chainId: chain.id
    });
	const { data: constants = {} } = await requestConstant();
	console.log(
		'[DEBUG]: calcRealTime: connected to: ',
		chain,
		', blockNumber: ',
		blockNumber,
		', blockHeight: ',
		blockHeight
	);
	const rate = constants.network[chain.name].block_rate;
	const relativeTime = (Number(blockHeight) - Number(blockNumber)) * rate;
	const now = new Date();
	const ret = new Date(now.setSeconds(now.getSeconds() + relativeTime));
	return ret;
};

export const LIMIT_ORDER_PLACED =
	'event LimitOrderPlaced(address indexed token, int24 indexed point, uint128 amount, uint128 earning, address indexed wallet)';
export const MARKET_ORDER_COMPLETED =
	'event MarketOrderCompleted(address indexed originToken, uint128 amount, uint128 earning)';
export const LIMIT_ORDER_CANCELED = 
    'event LimitOrderCanceled(address indexed originToken, int24 indexed point, uint128 refund, address indexed wallet)';
export const EARNING_CLAIMED = 
    'event EarningClaimed(address targetToken, int24 point, uint128 earning, address indexed wallet)';
export const SWAPPED = 
    'event Swapped(address originToken, int24 point, uint128 amount)';

export const getPairLog = async (
	address,
	event,
    args,
	fromBlock,
	toBlock = 'latest'
) => {
	const client = createPublicClient({
        chain: await getCurrentChain(),
        transport: http(),
    });

    fromBlock = typeof(fromBlock) == 'number' ? "0x"+fromBlock.toString(16) : fromBlock;
    toBlock = typeof(toBlock) == 'number' ? "0x"+toBlock.toString(16) : toBlock;

	let filter = {
		address,
		fromBlock,
		toBlock,
		event: parseAbiItem(event),
        args,
	};

	// const intrfc = new utils.Interface(abi);
	// const parseLogs = (rawLogs) => {
	// 	const parsedLogs = [];
	// 	rawLogs.forEach((log) => {
	// 		parsedLogs.push({ ...intrfc.parseLog(log), ...log });
	// 	});
	// 	return parsedLogs;
	// };

	return await client.getLogs(filter);
};

export const getPrice = async (
	contractAddr,
	blockHeight = undefined,
	isPoint = false
) => {
	return pairReadContract('startOff', undefined, contractAddr, blockHeight)
		.then((start) => {
			if (start) {
				return pairReadContract('curPoint', undefined, contractAddr, blockHeight);
			} else {
				return new Promise((resolve) => {
					resolve(undefined);
				});
			}
		})
		.then((point) => {
			if (isPoint) return point;
			return point2Price(point).toNumber();
		})
		.catch(() => {
            return isPoint ? undefined : 0
        });
};

export const ERC20ReadContract = async (functionName, args, address) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestERC20ABI();
    const wallet = await getAccountAddress();
	const data = await readContract(wagmiConfig, {
		address,
		abi,
		functionName,
		args,
        chainId: chain.id,
        account: wallet
	});
	console.log(
		'[DEBUG]: ERC20ReadContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', address: ',
		address,
		', data: ',
		data
	);
	return data;
};

export const ERC20ReadContracts = async (contracts) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestERC20ABI();
    const wallet = await getAccountAddress();
	for (let index = 0; index < contracts.length; index++) {
		const contract = contracts[index];
		contract.abi = abi;
        contract.chainId = chain.id;
        contract.account = wallet;
	}
	const data = await readContracts(wagmiConfig, { contracts });
	console.log(
		'[DEBUG]: ERC20ReadContracts: connected to: ',
		chain,
		', contracts: ',
		contracts,
		', data: ',
		data
	);
	return data.map(r=>r.result);
};

export const ERC20WriteContract = async (functionName, args, address) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestERC20ABI();
	let ok = false;
	let res;
	try {
		const {request} = await simulateContract(wagmiConfig, {
			address,
			abi,
			functionName,
			args,
            chainId: chain.id
		});
		res = await writeContract(wagmiConfig, request);
		ok = true;
	} catch (e) {
		const revert = e.details;
        const msg = e.shortMessage;
        res = revert || msg;
		if (!revert && ! msg) {
			console.log('[DEBUG]: ', JSON.stringify(e));
			res = 'Occuring error while writing to contract';
		} 
		ok = false;
	}
	console.log(
		'[DEBUG]: ERC20WriteContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', address: ',
		address,
		', ok: ',
		ok,
		', res: ',
		res
	);
	return { ok, res };
};

export const bitlyReadContract = async (functionName, args) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBitlyABI();
	const { data: constants = {} } = await requestConstant();
    const wallet = await getAccountAddress();
	const data = await readContract(wagmiConfig, {
		address: constants.network[chain.name].bitly_address,
		abi,
		functionName,
		args,
        chainId: chain.id,
        account: wallet
	});
	console.log(
		'[DEBUG]: bitlyReadContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', data: ',
		data
	);
	return data;
};

export const bitlyReadContracts = async (contracts) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBitlyABI();
	const { data: constants = {} } = await requestConstant();
    const wallet = await getAccountAddress();
	for (let index = 0; index < contracts.length; index++) {
		const contract = contracts[index];
		contract.abi = abi;
		contract.address = constants.network[chain.name].bitly_address;
        contract.chainId = chain.id;
        contract.account = wallet;
	}
	const data = await readContracts(wagmiConfig, { contracts });
	console.log(
		'[DEBUG]: bitlyReadContracts: connected to: ',
		chain,
		', contracts: ',
		contracts,
		', data: ',
		data
	);
	return data.map(r=>r.result);
};

export const bitlyWriteContract = async (functionName, args) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBitlyABI();
	const { data: constants = {} } = await requestConstant();

	let ok = false;
	let res;

	try {
		const {request} = await simulateContract(wagmiConfig, {
			address: constants.network[chain.name].bitly_address,
			abi,
			functionName,
			args,
            chainId: chain.id
		});
		res = await writeContract(wagmiConfig, request);
		ok = true;
	} catch (e) {
		const revert = e.details;
        const msg = e.shortMessage;
        res = revert || msg;
		if (!revert && ! msg) {
			console.log('[DEBUG]: ', JSON.stringify(e));
			res = 'Occuring error while writing to contract';
		} 
		ok = false;
	}
	console.log(
		'[DEBUG]: ERC20WriteContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', ok: ',
		ok,
		', res: ',
		res
	);
	return { ok, res };
};

export const pairReadContract = async (
	functionName,
	args,
	address,
	blockNumber
) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestPairABI();
    const wallet = await getAccountAddress();
	const data = await readContract(wagmiConfig, {
		address,
		abi,
		functionName,
		args,
		blockNumber,
        chainId: chain.id,
        account: wallet,
	});
	console.log(
		'[DEBUG]: pairReadContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', address: ',
		address,
		', args: ',
		args,
		', data: ',
		data,
        ', blockNumber: ',
        blockNumber
	);
	return data;
};

export const pairReadContracts = async (contracts) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestPairABI();
    const wallet = await getAccountAddress();
	for (let index = 0; index < contracts.length; index++) {
		const contract = contracts[index];
		contract.abi = abi;
        contract.chainId = chain.id;
        contract.account = wallet;
	}
	const data = await readContracts(wagmiConfig, { contracts });
	return data.map(r=>r.result);
};

export const pairWriteContract = async (functionName, args, address) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestPairABI();
	let ok = false;
	let res;
	try {
		const {request} = await simulateContract(wagmiConfig, {
			address,
			abi,
			functionName,
			args,
            chainId: chain.id
		});
		res = await writeContract(wagmiConfig, request);
		ok = true;
	} catch (e) {
		const revert = e.details;
        const msg = e.shortMessage;
        res = revert || msg;
		if (!revert && ! msg) {
			console.log('[DEBUG]: ', JSON.stringify(e));
			res = 'Occuring error while writing to contract';
		} 
		ok = false;
	}
	console.log(
		'[DEBUG]: pairWriteContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', address: ',
		address,
		', ok: ',
		ok,
		', res: ',
		res
	);
	return { ok, res };
};

export const bankReadContract = async (functionName, args) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBankABI();
	const bankAddress = await bitlyReadContract('bank');
    const wallet = await getAccountAddress();
	const data = await readContract(wagmiConfig, {
		adress: bankAddress,
		abi,
		functionName,
		args,
        chainId: chain.id,
        account: wallet
	});
	console.log(
		'[DEBUG]: bankReadContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', data: ',
		data
	);
	return data;
};

export const bankReadContracts = async (contracts) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBankABI();
	const bankAddress = await bitlyReadContract('bank');
    const wallet = await getAccountAddress();
	for (let index = 0; index < contracts.length; index++) {
		const contract = contracts[index];
		contract.abi = abi;
		contract.address = bankAddress;
        contract.chainId = chain.id;
        contract.account = wallet;
	}
	const data = await readContracts(wagmiConfig, { contracts });
	console.log(
		'[DEBUG]: bankReadContracts: connected to: ',
		chain,
		', contracts: ',
		contracts,
		', data: ',
		data
	);
	return data.map(r=>r.result);
};

export const bankWriteContract = async (functionName, args) => {
	const chain = await getCurrentChain();
	const { data: abi = {} } = await requestBankABI();
	const bankAddress = await bitlyReadContract('bank');

	let ok = false;
	let res;

	try {
		const {request} = await simulateContract(wagmiConfig, {
			address: bankAddress,
			abi,
			functionName,
			args,
            chainId: chain.id
		});
		res = await writeContract(wagmiConfig, request);
		ok = true;
	} catch (e) {
		const revert = e.details;
        const msg = e.shortMessage;
        res = revert || msg;
		if (!revert && ! msg) {
			console.log('[DEBUG]: ', JSON.stringify(e));
			res = 'Occuring error while writing to contract';
		} 
		ok = false;
	}
	console.log(
		'[DEBUG]: bankWriteContract: connected to: ',
		chain,
		', func: ',
		functionName,
		', args: ',
		args,
		', ok: ',
		ok,
		', res: ',
		res
	);
	return { ok, res };
};

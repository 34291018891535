import { compose, applyMiddleware, createStore } from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducer from './reducers/reducer';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

const middlewares = [promise, thunk];
const middleware = applyMiddleware(...middlewares);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
};

const myPersistReducer = persistReducer(persistConfig, reducer);
const store = createStore(
    myPersistReducer,
    composeEnhancers(middleware)
);
export const persistor = persistStore(store);
export default store;
